// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./imgs/icon-submit-success.png";
import ___CSS_LOADER_URL_IMPORT_1___ from "./imgs/icon-submit-fail.png";
import ___CSS_LOADER_URL_IMPORT_2___ from "./imgs/icon-time-fill.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".plv-iar-submit-tips-default__main{z-index:10001;position:absolute;padding:0 16px;top:50%;left:50%;margin-top:-90px;margin-left:-100px;height:180px;width:200px;background:#fff;box-shadow:0 0 2px 0 #ccc;border-radius:8px}.plv-iar-submit-tips-default__main--mobile{height:142px;width:180px;margin:0;transform:translate(-50%, -50%)}.plv-iar-submit-tips-default__main--mobile .plv-iar-submit-tips-default__main__icon{margin:24px auto 20px}.plv-iar-submit-tips-default__main__icon{width:60px;height:60px;margin:42px auto 20px}.plv-iar-submit-tips-default__main__content{font-size:14px;color:#333;text-align:center}.plv-iar-submit-tips-default__icon-success{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100%;background-position:center}.plv-iar-submit-tips-default__icon-failed{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:100%;background-position:center}.plv-iar-submit-tips-default__icon-time{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-size:100%;background-position:center}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

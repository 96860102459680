const zhCN = {
  submit: {
    submitSuccess: '提交成功',
    submitFailed: '提交失败',
  },
};

const en = {
  submit: {
    submitSuccess: 'Success',
    submitFailed: 'Submission Failed',
  },
};

export default {
  zh_CN: zhCN,
  en,
};

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./imgs/icon-product.png";
import ___CSS_LOADER_URL_IMPORT_1___ from "./imgs/icon-recruitment.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".plv-iar-product__total{color:#fff;font-size:12px;display:flex;align-items:center;padding:16px 16px 14px;position:-webkit-sticky;position:sticky;top:0;left:0;background:#202127;z-index:30}.plv-iar-product__total span{color:#f15d5d;margin-left:3px;margin-right:3px}.plv-iar-product__total__icon{width:12px;height:12px;margin-right:6px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%}.plv-iar-product__total__icon--recruitment{width:12px;height:12px;margin-right:6px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:100% 100%}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

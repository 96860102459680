// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./imgs/empty.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".plv-iar-product__empty{width:100%;line-height:18px;text-align:center;color:rgba(255,255,255,.3);font-size:14px;position:absolute;top:26%;padding:0 10px;box-sizing:border-box;word-break:break-word;display:flex;flex-direction:column;justify-content:center;align-items:center}.plv-iar-product__empty__img{width:88px;height:88px;margin-bottom:8px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;opacity:.3}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"plv-iar-product__item pws-iar-product-list-item",class:{
    'plv-iar-product__item--job': _vm.isJobType,
    'plv-iar-product__item--noCoverJob': _vm.isJobType && !_vm.good.cover
  }},[(_vm.good.cover)?_c('div',{staticClass:"plv-iar-product__item__cover pws-iar-product-list-cover"},[_c('img',{attrs:{"src":_vm.good.cover}}),_vm._v(" "),_c('span',{staticClass:"plv-iar-product__item__cover__number pws-iar-product-list-cover-number"},[_vm._v(_vm._s(_vm.number))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"plv-iar-product__item__info"},[_c('div',{staticClass:"plv-iar-product__item__name",attrs:{"title":_vm.good.name}},[(!_vm.good.cover)?_c('span',{staticClass:"plv-iar-product__item__name__number pws-iar-product-list-number"},[_vm._v(_vm._s(_vm.number))]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"plv-iar-product__item__name__text pws-product-theme-text-color"},[_vm._v(_vm._s(_vm.good.name))]),_vm._v(" "),(_vm.productVm.descLabel && _vm.good.productDesc && _vm.good.productType !== 'position')?_c('div',{staticClass:"plv-iar-product__item__name__desc pws-iar-product-list-desc-label",on:{"click":_vm.clickDescEnter}},[_c('span',[_vm._v(_vm._s(_vm.productVm.descLabel))]),_vm._v(" "),_c('span',{staticClass:"plv-iar-product__item__name__desc__icon pws-iar-product-list-right-arrow"})]):_vm._e()]),_vm._v(" "),(_vm.labels.length)?_c('div',{staticClass:"plv-iar-product__item__labels"},_vm._l((_vm.labels),function(label,index){return _c('product-label',{key:index,staticClass:"plv-iar-product__item__labels__item",class:{
          'plv-iar-product__item__labels__item--job': _vm.good.productType === 'position'
        },attrs:{"enable-watch-skin":_vm.isJobType,"max-width":_vm.isJobType ? Math.min(label.length * 12.1, 80) : 0,"text":label}})}),1):_vm._e(),_vm._v(" "),(_vm.good.productDesc && _vm.good.productType !== 'position')?_c('div',{staticClass:"plv-iar-product__item__desc pws-iar-product-list-desc",class:{
        'plv-iar-product__item__desc--pr': _vm.labels.length
      },attrs:{"title":_vm.good.productDesc}},[_c('span',[_vm._v(_vm._s(_vm.good.productDesc))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"plv-iar-product__item__bottom"},[(_vm.good.productType === 'normal')?[_c('div',{staticClass:"plv-iar-product__item__price",class:{
            'plv-iar-product__item__price--free': _vm.good.realPrice === 0
          }},[_c('span',[_vm._v(_vm._s(_vm.realPrice(_vm.good.realPrice)))])]),_vm._v(" "),_c('div',{staticClass:"plv-iar-product__item__oldprice pws-body-font-color3"},[_vm._v(_vm._s(_vm.oldPrice(_vm.good.price)))])]:_vm._e(),_vm._v(" "),(_vm.good.productType === 'finance')?[_c('div',{staticClass:"plv-iar-product__item__price"},[_c('span',[_vm._v(_vm._s(_vm.good.yield))])])]:_vm._e(),_vm._v(" "),(_vm.good.productType === 'position')?[_c('div',{staticClass:"plv-iar-product__item__price"},[_c('span',[_vm._v(_vm._s(_vm.treatment))])])]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"plv-iar-product__item__btn pws-iar-product__item__btn",class:{
          'plv-iar-product__item__job': _vm.good.productType === 'position'
        }},[(_vm.good.productType === 'position')?[_c('div',{staticClass:"plv-iar-product__item__btn plv-iar-product__item__job__detail",on:{"click":function($event){return _vm.$emit('jobDetail', _vm.good)}}},[_c('div',[_vm._v(_vm._s(_vm.iarTrans('product.jobDetail')))])])]:_vm._e(),_vm._v(" "),_c('buy-button',{style:({
            maxWidth: _vm.isJobType ? ((Math.min(_vm.good.btnShow.length * 16, 98)) + "px") : 'auto'
          }),attrs:{"good":_vm.good},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var buttonText = ref.buttonText;
return [_c('div',{staticClass:"plv-iar-product__item__btn__content pws-iar-product-list-button"},[(!_vm.isJobType)?[_vm._v("\n                "+_vm._s(buttonText)+"\n              ")]:[_vm._v("\n                "+_vm._s(_vm.good.btnShow || '投递简历')+"\n              ")]],2)]}}])})],2)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }